import $http from "@/utils/request.js";
import qs from "qs";




//点击排序后的列表
export function staffServiceGroupGetlist() {
  return $http.post('/staffServiceGroup/getList')
}


//员工服务列表
export function getServices(data) {
  return $http.post('/staffServiceGroup/getServices',qs.stringify(data))
}


//删除某项员工服务
export function deleteServices(data) {
  return $http.post('/staffService/del',qs.stringify(data))
}


//删除某非专业
export function deleteServiceGroup(data) {
  return $http.post('/staffServiceGroup/del',qs.stringify(data))
}


//添加/编辑分组
export function setUpServicesGroup(data) {
  if (data.group_id == "" || !data.group_id) {
    delete data.group_id
    return $http.post('/staffServiceGroup/add',qs.stringify(data))
  }else {
    return $http.post('/staffServiceGroup/edit',qs.stringify(data))
  }
}


//添加/编辑配置项
export function setUpServices(data) {
  console.log(qs.stringify(data))
  if (data.service_id == "" || !data.service_id) {
    delete data.service_id
    return $http.post('/staffService/add',qs.stringify(data))
  }else {
    return $http.post('/staffService/edit',qs.stringify(data))
  }
}

//编辑分组或员工服务排序
export function editSort(data) {
  // console.log(qs.stringify(data))
  return $http.post('/staffServiceGroup/editSort',data)
}


//更新封面图
export function update(data) {
  // console.log(qs.stringify(data))
  return $http.post('/coverImage/update',data)
}


//保存
export function preserve(data) {
  // console.log(qs.stringify(data))
  return $http.post('staffService/preserve',data)
}


//获取员工服务列表【手机端展示】
export function getServicesm(data) {
  // console.log(qs.stringify(data))
  return $http.post('staffServiceGroup/getServices',data)
}


//获取员工服务列表【手机端展示】
export function getStaffServiceData(data) {
  // console.log(qs.stringify(data))
  return $http.post('/staffServiceGroup/getStaffServiceData',data)
}

