<template>
  <div class="bodys" v-loading="isLoading">
    <div class="bodyLeftBottom" v-if="isShow">
      <div class="bodyLeftBottomImg">
        <div class="bodyLeftBottomImgdw"></div>
        <div class="bodyLeftBottomImgx">
          <!-- style="width : 90%"  -->
          <img
            style="border-radius : 4px"
            :src="backgroundImg != '' && backgroundImg ? backgroundImg : require('@/assets/images/staff/background.png')"
          />
        </div>
      </div>
      <div class="personnelServices">
        <div v-if="setList.group && setList.group.length <= 1" class="noData">
          <img src="@/assets/images/noData.png" alt />
          暂无数据
        </div>

        <div
          v-for="(item,index) in setList.group"
          :key="index"
          class="stFor"
          v-show="!(item.name == '未分组' && item.service.length == 0)"
        >
          <div
            class="personnelServicesText"
            v-if="!(item.name == '未分组')"
            :style="item.service.length != 0 ? '' : 'border-bottom : 0px;'"
          >
            <div>{{item.name}}</div>
          </div>
          <div class="personnelServicesBody" v-if="item.service.length != 0">
            <div
              v-for="(inItem,i) in item.service"
              :key="i"
              :class="i % 3 == 2 ? 'isfor' : 'isfor linx'"
              :style="i > 2 ? 'border-top: 1px solid #F5F5F5' : ''"
              @click="wxPost(inItem.service_id)"
            >
              <!-- 左上角logo -->
              <div class="isforDw">
                <img v-if="inItem.is_show == 1" src="@/assets/images/staff/use2.png" alt />
              </div>

              <!-- 服务名称 -->
              <div class="isforImg">
                <img src="@/assets/images/staff/information2.png" alt />
              </div>
              <div class="isforData">{{inItem.title}}</div>
            </div>
            <div v-for="list in _fill_list_3(item.service)" :key="'i' + list" style="width: 33.2%"></div>
          </div>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="vanisShow"
      :closeable="false"
      show-cancel-button
      @cancel="cancel"
      @confirm="awaken"
    >
      <div style="width : 80%;margin-left : 10%" class="groups">
        <van-radio-group v-model="radio" class="group">
          <div v-for="(item) in user_id" :key="item" class="userIdFor">
            <van-radio :name="item">
              <wxComponent type="userName" :openid="item" />
            </van-radio>
          </div>
        </van-radio-group>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { getServicesm, getStaffServiceData } from "@/api/staff";
import { getConfigNoLoginApi } from "@/api/config";
import { otherBrowser, wxAgentConfig, wxConfig } from "@/utils/wx";
import wxComponent from "@/components/wx";

export default {
  name: "WorkbenchFrontStaffing",
  components: { wxComponent },
  data() {
    return {
      setList: {
        group: []
      }, // 数据
      backgroundImg: "", // 顶部图片
      s: "", //url 携带参数
      firstEntry: true,
      type: 1,
      vanisShow: false, //选人弹框是否打开
      radio: "", //被选择的人物
      user_id: [],
      agent_id: "",
      corpid: "",
      isShow: false,
      isLoading: true
    };
  },

  created() {
    if (window.flag == 1) {
      wx.closeWindow();
      return;
    }
    window.flag = 0;
    let arr = location.href.split("?")[1].split("&");
    let s_Data = "";
    arr.forEach(item => {
      item.split("=")[0] == "s"
        ? (s_Data = decodeURIComponent(item.split("=")[1]))
        : "";
    });
    this.s = s_Data;
    console.log(this.s,'this.s')
    let data = JSON.parse(s_Data);
    this.agent_id = data.agent_id;
    this.corpid = data.corpid;
    this.type = JSON.parse(s_Data).type;
    this.authentication();
    // this.getData()
  },

  methods: {
    authentication() {
      getConfigNoLoginApi({
        type: 1,
        current_url: location.href.split("#")[0],
        s: this.s
      }).then(async data => {
        const getConfig = {
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.data.corpid, // 必填，企业微信的corpID
          timestamp: data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.data.noncestr, // 必填，生成签名的随机串
          signature: data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: [
            "openEnterpriseChat",
            "closeWindow",
            "selectEnterpriseContact",
            "WWOpenData"
          ]
        };

        await wxConfig(getConfig);
        this.authentication2();
      });
    },

    authentication2() {
      getConfigNoLoginApi({
        type: 2,
        current_url: location.href.split("#")[0],
        s: this.s
      }).then(data => {
        let config = {
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          corpid: data.data.corpid, // 必填，企业微信的corpID
          agentid: this.agent_id,
          timestamp: data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.data.noncestr, // 必填，生成签名的随机串
          signature: data.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: [
            "WWOpenData",
            "selectEnterpriseContact",
            "openEnterpriseChat",
            "closeWindow"
          ],
          fail: function(res) {
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          }
        };

        wxAgentConfig(config).then(_ => {
          WWOpenData?.bindAll?.(document.querySelectorAll("ww-open-data"));
        });
        if (this.type == 1) {
          window.flag = 1;
          this.wxPost();
          this.isShow = false;
        } else {
          this.isShow = true;
          this.getData();
        }
      });
    },

    cancel() {
      if (this.type == 1) {
        wx.closeWindow();
      }
    },
    awaken() {
      // 唤醒会话
      if (this.radio.length == 0) {
        this.$message.error("请选择你要联系的人员");
        setTimeout(() => {
          this.vanisShow = true;
        }, 200);
        return;
      }
      wx.openEnterpriseChat({
        userIds: this.radio, //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        groupName: "", // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success: function(res) {
          // 回调
          if (that.type == 1) {
            wx.closeWindow();
          }
        },
        fail: function(res) {
          if (res.errMsg.indexOf("function not exist") > -1) {
            that.$message.error("版本过低请升级");
          }
        }
      });
    },
    wxPost(service_id) {
      getStaffServiceData({
        service_id,
        url: location.href.split("#")[0],
        s: this.s
      }).then(async data => {
        this.isLoading = false;
        let that = this;
        // const getConfig = {
        //   beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        //   debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        //   appId: data.data.appId, // 必填，企业微信的corpID
        //   timestamp: data.data.enterprise.timestamp, // 必填，生成签名的时间戳
        //   nonceStr: data.data.enterprise.nonceStr, // 必填，生成签名的随机串
        //   signature: data.data.enterprise.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        //   jsApiList: [
        //     "openEnterpriseChat",
        //     "closeWindow",
        //     "selectEnterpriseContact",
        //     "WWOpenData"
        //   ],

        // };

        // await wxConfig(getConfig)

        // let config = {
        //   beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        //   debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        //   corpid: this.corpid, // 必填，企业微信的corpID
        //   agentid: this.agent_id,
        //   timestamp: data.data.agent.timestamp, // 必填，生成签名的时间戳
        //   nonceStr: data.data.agent.nonceStr, // 必填，生成签名的随机串
        //   signature: data.data.agent.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
        //   jsApiList: ['WWOpenData',"selectEnterpriseContact","openEnterpriseChat","closeWindow"],
        //   fail: function(res) {
        //       if(res.errMsg.indexOf('function not exist') > -1){
        //           alert('版本过低请升级')
        //       }
        //   }
        // }
        wx.ready(function(ref) {
          if (data.data.user.length > 1) {
            that.$router.push({
              path: "/pick",
              query: {
                user_id: JSON.stringify(data.data.user),
                type: that.type
              }
            });
            return;
          }
          wx.openEnterpriseChat({
            userIds: data.data.user[0].user_id, //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
            groupName: "", // 会话名称。单聊时该参数传入空字符串""即可。
            chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
            success: function(res) {
              // 回调
              if (that.type == 1) {
                wx.closeWindow();
              }
            },
            fail: function(res) {
              if (res.errMsg.indexOf("function not exist") > -1) {
                that.$message.error("版本过低请升级");
              }
            }
          });
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        });
      });
    },
    getData() {
      getServicesm({
        s: this.s
      }).then(data => {
        if (data.code == 200) {
          // let datas = [...data.data["group"][0].service];
          // data.data["group"][0].service.push(...datas);
          this.setList = data.data;
          this.backgroundImg = data.data?.cover_image?.url; // 判断是否有这个图片 如果后台没有传就显示默认图片
          this.setList["group"].push({
            // 把未分组的添加进group 里方便渲染
            name: "未分组",
            service: this.setList["service"]
          });
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.$message.error(data.message);
        }
      });
    },
    // 补全
    _fill_list_3(data) {
      let fill = 3 - (data.length % 3);
      return fill == 3 ? 0 : fill;
    }
  }
};
</script>

<style lang="less" scoped>
.linx { 
  border-right: 1px solid #f5f5f5;
}
.stFor {
  width: 100%;
  background: white;
  border-radius: 4px;
  padding-bottom: 2px;
  margin-bottom: 10px;
}
.bodyLeftBottomImgdw {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 60px;
  width: 100%;
  background: #3875c6;
}
.userIdFor {
  height: 20px;
  margin-bottom: 10px;
}
.groups {
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
// .group{
//   margin-bottom: 10px;
// }
.isfors {
  background: #eeeeee !important;
  // cursor:no-drop;
}
.isfor {
  // width: 108px;
  width: 33.2%;
  height: 115px;
  background: #ffffff;
  // border: 1px solid #d1dae2;
  // border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  // border-radius: 4px;
  justify-content: center;
  align-items: center;
  // margin-bottom: 10px;
  .isforData {
    font-size: 14px;
    color: #303133;
  }
  .isforImg {
    margin-top: -4px;
    margin-bottom: 8px;
  }
  img {
    width: 36px;
    height: 36px;
    margin-top: 4px;
  }
  .isforDw {
    width: 15px;
    height: 18px;
    // height: 13px;
    position: absolute;
    right: 2px;
    top: 0;
    img {
      position: relative;
      // top: -2px;
      width: 100%;
      height: 18px;
      margin-top: 0 !important;
    }
  }
}

.noData {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  height: 300px;
  font-size: 14px;
  color: #333333;
  img {
    margin-bottom: 5px;
    width: 125px;
    // height: 95px;
  }
}
.bodys {
  background: #f5f5f5;
  height: 100vh;
}
.bodyLeftBottom {
  background: #f5f5f5;
  border-radius: 2px;
  .bodyLeftBottomImg {
    padding: 15px 15px 20px 15px;
    position: relative;
  }
  .bodyLeftBottomImgx {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 3;
    img {
      max-height: 140px;
      width: 100%;
    }
  }
  .bodyLeftBottomImgx:hover > .isforHide {
    z-index: 10;
    opacity: 1;
  }
  .personnelServices {
    padding: 0 15px;
    .personnelServicesBody {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .personnelServicesText {
      display: flex;
      align-items: center;
      // margin-bottom: 13px;
      height: 36px;
      border-bottom: 1px solid #f5f5f5;
      font-size: 12px;
      line-height: 36px;
      > div {
        margin-left: 15px;
        height: 22px;
        display: flex;
        align-items: center;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
      }
    }
  }
  .bodyLeftBottomb {
    position: absolute;
    // width: 100%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 72px;
    background: #ffffff;
    border-top: 1px solid #d6dbdf;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .leftButtonb {
      padding: 9px 38px;
      background: #ffffff;
      font-size: 16px;
      color: #0082ef;
      border: 1px solid #0082ef;
      border-radius: 4px;
    }
    .leftButton {
      padding: 9px 10px;
      border: 1px solid #d6dbdf;
      // height: 20px;
      background: #ffffff;
      color: #303133;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }
  }
}
</style>